import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route, Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [component, setComponent] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        try {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND_LINK + "/check",
            config
          );
          console.log(response);
          setComponent(<Outlet />);
        } catch (error) {
          setComponent(<Navigate to="/login" />);
        }
      } else {
        setComponent(<Navigate to="/login" />);
      }
    };

    checkToken();
  }, []);

  return component;
};

export default PrivateRoute;
