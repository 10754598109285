import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Message } from "primereact/message";
import axios from "axios";
import styles from "../styles/login.module.css";

function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailWrong, setIsEmailWrong] = useState(false);
  const [isPassordWrong, setPasswordWrong] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform authentication here (e.g. sending a request to the server)
    const response = await axios
      .post(process.env.REACT_APP_BACKEND_LINK + "/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.status === "authorized") {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("id", response.data.id);
          setIsEmailWrong(false);
          setPasswordWrong(false);
          setIsAuthenticated(true);
        } else if (response.data.status === "wrong email") {
          setIsEmailWrong(true);
          setPasswordWrong(false);
          setIsError(false);
        } else if (response.data.status === "wrong pass") {
          setPasswordWrong(true);
          setIsEmailWrong(false);
          setIsError(false);
        } else {
          setIsError(true);
          setPasswordWrong(false);
          setIsEmailWrong(false);
        }
        console.log(response);
      });

    // Clear the form
    setEmail("");
    setPassword("");
  };
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className={styles.screen}>
      <div className={styles.form}>
        <TabView>
          <TabPanel header="Login">
            <div className={styles.input}>
              <form onSubmit={handleSubmit}>
                <div className={styles.input_field}>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-user" />
                    </span>

                    <InputText
                      placeholder="example@gmail.com"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.input_field}>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-key" />
                    </span>
                    <InputText
                      placeholder="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.submit}>
                  <Button type="submit" label="Login" />
                </div>
              </form>
            </div>
          </TabPanel>
          <TabPanel header="Register">
            <div className={styles.input}>
              <form onSubmit={handleSubmit}>
                <div className={styles.input_field}>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-user" />
                    </span>

                    <InputText
                      placeholder="example@gmail.com"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.input_field}>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-key" />
                    </span>
                    <InputText
                      placeholder="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.submit}>
                  <Button type="submit" label="Login" />
                </div>
              </form>
            </div>
          </TabPanel>
        </TabView>
        <div className={styles.submit}>
          {isEmailWrong ? (
            <Message severity="error" text="Invalid email" />
          ) : null}
          {isPassordWrong ? (
            <Message severity="error" text="Invalid password" />
          ) : null}
          {isError ? (
            <Message severity="error" text="Something went wrong" />
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default LoginForm;
