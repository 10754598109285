import React, { useEffect, useState } from "react";
import Sidebar from "./SideBar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./AddTraining.css";
import "react-dropzone-uploader/dist/styles.css";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { useLocation } from "react-router-dom";
import "./EditTraining.css";

function EditTraining() {
  const [customName, setCustomName] = useState("");
  const [customDescription, setCustomDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [Category, setCategory] = useState("");
  const [Features, setFeatures] = useState("");
  const [Objectives, setObjectives] = useState("");
  const [Audience, setAudience] = useState("");
  const [Modes, setModes] = useState("");
  const [Assessment, setAssessment] = useState("");
  const [Available, setAvailable] = useState("");
  const [Language, setLanguage] = useState("");
  const [numberOfScenarios, setnumberOfScenarios] = useState("");
  const [Area, setArea] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [Duration, setDuration] = useState("");
  const [currentSelection, setCurrentSelection] = useState("VR Training");
  const location = useLocation();
  const { rowData } = location.state;

  // Now you can access the rowData object
  console.log(rowData);

  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (currentSelection === "Companies") {
      navigate("/");
    } else if (currentSelection === "Plans") {
      navigate("/plans");
    }
  }, [currentSelection, navigate]);

  const fetchData = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const responseVR = await axios
      .get(
        process.env.REACT_APP_BACKEND_LINK +
          "/vr/get/training/metaenga/" +
          rowData.id,
        config
      )
      .catch((error) => {
        console.log(error);
        toast.error("Error Fetching Data");
      });

    setCustomName(responseVR.data.data.name);
    setCustomDescription(responseVR.data.data.description);
    setShortDescription(responseVR.data.data.shortDescription);
    setFeatures(responseVR.data.data.features);
    setCategory(responseVR.data.data.category);
    setObjectives(responseVR.data.data.objectives);
    setAudience(responseVR.data.data.audience);
    setModes(responseVR.data.data.modes);
    setAssessment(responseVR.data.data.assessment);
    setAvailable(responseVR.data.data.available);
    setLanguage(responseVR.data.data.lang);
    setnumberOfScenarios(responseVR.data.data.numberOfScenarios);
    setArea(responseVR.data.data.area);
    if (
      responseVR.data.data.createdAt === "" ||
      responseVR.data.data.createdAt === null
    ) {
      setSelectedDate(null);
    } else {
      let preloadedDate = new Date(responseVR.data.data.createdAt);
      setSelectedDate(preloadedDate);
    }

    setDuration(responseVR.data.data.duration);
    console.log(responseVR.data.data);
  };

  useEffect(() => {
    localStorage.setItem("currentSelection", currentSelection);
  }, [currentSelection]);

  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };
  const handleCustomNameChange = (e) => {
    setCustomName(e.target.value);
  };
  const handleCustomDescriptionChange = (e) => {
    setCustomDescription(e.target.value);
  };
  const handleShortDescriptionChange = (e) => {
    setShortDescription(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };
  const handleFeaturesChange = (e) => {
    setFeatures(e.target.value);
  };
  const handleObjectivesChange = (e) => {
    setObjectives(e.target.value);
  };
  const handleAudienceChange = (e) => {
    setAudience(e.target.value);
  };
  const handleModesChange = (e) => {
    setModes(e.target.value);
  };
  const handleAssessmentChange = (e) => {
    setAssessment(e.target.value);
  };
  const handleAvailableChange = (e) => {
    setAvailable(e.target.value);
  };
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };
  const handlenumberOfScenariosChange = (e) => {
    setnumberOfScenarios(e.target.value);
  };
  const handleAreaChange = (e) => {
    setArea(e.target.value);
  };
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
  };
  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };
  const handleEdit = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const data = {
      name: customName,
      description: customDescription,
      shortDescription: shortDescription,
      features: Features,
      category: Category,
      objectives: Objectives,
      audience: Audience,
      modes: Modes,
      assessment: Assessment,
      available: Available,
      lang: Language,
      numberOfScenarios: numberOfScenarios,
      area: Area,
      createdAt: selectedDate,
      duration: Duration,
    };
    const response = await axios
      .post(
        `${process.env.REACT_APP_BACKEND_LINK}/edit/training/${rowData.id}`,
        data,
        config
      )
      .catch((error) => {
        console.log(error);
        toast.error("Error Editing Training");
      });
    if (response.status === 200) {
      toast.success("Training Edited Successfully");
      fetchData();
    }
  };
  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 1100,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection="VR Training"
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Button
              icon="pi pi-arrow-left"
              className="dot-button"
              style={{
                background: "none",
                border: "none",
                boxShadow: "none",
                marginRight: "-15px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
              onClick={() => {
                navigate("/", { state: { status: "VR Training" } });
              }}
            ></Button>
            <h2
              id="title"
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                marginLeft: "10px",
                color: "rgba(46, 58, 75, 0.7)",
              }}
            >
              {currentSelection} /
            </h2>
            <h2
              id="title"
              style={{ margin: 0, whiteSpace: "nowrap", marginLeft: "41px" }}
            >
              Edit Training {rowData.id}
            </h2>
            <Button
              label="Save"
              className="custom-button"
              style={{ marginLeft: "130px" }}
              onClick={handleEdit}
            />
          </div>
          <div className="flex flex-column gap-2" style={{ flex: 1 }}>
            <div
              className="flex flex-row gap-2"
              style={{ marginTop: "35px", flex: 1 }}
            >
              <div
                className="flex flex-column gap-2"
                style={{ flex: 1, minWidth: "300px" }}
              >
                <label className="font-text" htmlFor="training title">
                  Training Title
                </label>
                <InputText
                  id="training-title"
                  value={customName}
                  onChange={handleCustomNameChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Category
                </label>
                <InputText
                  id="training-title"
                  value={Category}
                  onChange={handleCategoryChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Features
                </label>
                <InputText
                  id="training-title"
                  value={Features}
                  onChange={handleFeaturesChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Objectives
                </label>
                <InputText
                  id="training-title"
                  value={Objectives}
                  onChange={handleObjectivesChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Modes
                </label>
                <InputText
                  id="training-title"
                  value={Modes}
                  onChange={handleModesChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Available
                </label>
                <InputText
                  id="training-title"
                  value={Available}
                  onChange={handleAvailableChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <div className="flex flex-row gap-2">
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                    <label
                      className="font-text"
                      htmlFor="description title"
                      style={{ marginTop: "30px" }}
                    >
                      Number of scenarios
                    </label>
                    <InputText
                      id="training-title"
                      value={numberOfScenarios}
                      onChange={handlenumberOfScenariosChange}
                      aria-describedby="training-title-help"
                      style={{ width: "25%" }}
                      keyfilter="pint" // Allow only positive integers
                    />
                  </div>
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                    <label
                      className="font-text"
                      htmlFor="description title"
                      style={{ marginTop: "30px" }}
                    >
                      Training area (m²)
                    </label>
                    <InputText
                      id="training-title"
                      value={Area}
                      onChange={handleAreaChange}
                      aria-describedby="training-title-help"
                      style={{ width: "25%" }}
                      keyfilter="pint" // Allow only positive integers
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex flex-column gap-2"
                style={{ flex: 1, minWidth: "300px" }}
              >
                <label className="font-text" htmlFor="description title">
                  Description
                </label>
                <InputTextarea
                  id="description title"
                  autoResize={false}
                  value={customDescription}
                  rows={7}
                  onChange={handleCustomDescriptionChange}
                  aria-describedby="description title-help"
                  style={{ width: "76%", resize: "none"}}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "21px" }}
                >
                  Short description
                </label>
                <InputText
                  id="training-title"
                  value={shortDescription}
                  onChange={handleShortDescriptionChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Audience
                </label>
                <InputText
                  id="training-title"
                  value={Audience}
                  onChange={handleAudienceChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Assessment
                </label>
                <InputText
                  id="training-title"
                  value={Assessment}
                  onChange={handleAssessmentChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <label
                  className="font-text"
                  htmlFor="description title"
                  style={{ marginTop: "30px" }}
                >
                  Language
                </label>
                <InputText
                  id="training-title"
                  value={Language}
                  onChange={handleLanguageChange}
                  aria-describedby="training-title-help"
                  style={{ width: "76%" }}
                />
                <div className="flex flex-row gap-2">
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                    <label
                      className="font-text"
                      htmlFor="description title"
                      style={{ marginTop: "30px" }}
                    >
                      Creation date
                    </label>
                    <Calendar
                      id="date-input"
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="custom-calendar"
                      appendTo={document.body}
                      showIcon={true} // Optional: Show a calendar icon for easier date selection
                      style={{ width: "55%" }}
                    />
                  </div>
                  <div
                    className="flex flex-column gap-2"
                    style={{ flex: 1, minWidth: "300px" }}
                  >
                    <label
                      className="font-text"
                      htmlFor="description title"
                      style={{ marginTop: "30px" }}
                    >
                      Duration
                    </label>
                    <InputText
                      id="training-title"
                      value={Duration}
                      onChange={handleDurationChange}
                      aria-describedby="training-title-help"
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditTraining;
