import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import buildIcon from "./../images/build.png";
import cardIcon from "./../images/card.png";
import planIcon from "./../images/plan.png";
import insightIcon from "./../images/insightIcon.png";
import logOutIcon from "./../images/log-out.png"
import logo from "./../images/logo.png";
import "./sideBar.css";

const Sidebar = ({ UIselection, onSelectionChange }) => {
  const [currentSelection, setCurrentSelection] = useState(UIselection);

  const navigate = useNavigate();

  const handleClick = (selection) => {
    setCurrentSelection(selection);
    onSelectionChange(selection);
    localStorage.setItem("currentSelection", selection);
    navigate("/");
  };
  const handleClickInsight = () => {
    setCurrentSelection("Insights");
    onSelectionChange("Insights");
    localStorage.setItem("currentSelection", "Insights");
    navigate("/insight");
  }

  const buttonStyles = {
    background: "none",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: 0,
    margin: 0,
    width: "100%",
    textAlign: "left",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.035em",
    color: currentSelection === "Companies" ? "#005DFF" : "#2E3A4B",
    display: "flex",
    alignItems: "center",
  };

  const companyIconStyles = {
    marginRight: "8px",
    marginLeft: "26px",
    height: "24px",
    width: "24px",
    filter:
      currentSelection === "Companies"
        ? "invert(33%) sepia(99%) saturate(4489%) hue-rotate(203deg) brightness(94%) contrast(103%)"
        : "none",
  };

  const VRtrainingsIconStyles = {
    marginRight: "6px",
    marginLeft: "26px",
    height: "24px",
    width: "24px",
    filter:
      currentSelection === "VR Training"
        ? "invert(33%) sepia(99%) saturate(4489%) hue-rotate(203deg) brightness(94%) contrast(103%)"
        : "none",
  };

  const PlantrainingsIconStyles = {
    marginRight: "6px",
    marginLeft: "26px",
    height: "24px",
    width: "24px",
    filter:
      currentSelection === "Plans"
        ? "invert(33%) sepia(99%) saturate(4489%) hue-rotate(203deg) brightness(94%) contrast(103%)"
        : "none",
  };
  const InsighttrainingsIconStyles = {
    marginRight: "10px",
    marginLeft: "29px",
    height: "18px",
    width: "18px",
    filter:
      currentSelection === "Insights"
        ? "invert(33%) sepia(99%) saturate(4489%) hue-rotate(203deg) brightness(94%) contrast(103%)"
        : "none",
  };
  const LogoutIconStyles = {
    marginRight: "10px",
    marginLeft: "29px",
    height: "18px",
    width: "18px",
    
  };

  const companyIcon = (
    <img src={buildIcon} alt="Build" style={companyIconStyles} />
  );
  const VRIcon = (
    <img src={cardIcon} alt="Card" style={VRtrainingsIconStyles} />
  );
  const PlanIcon = (
    <img src={planIcon} alt="Card" style={PlantrainingsIconStyles} />
  );
  const InsightIcon = (
    <img src={insightIcon} alt="Card" style={InsighttrainingsIconStyles} />
  );
  const LogoutIcon = (
    <img src={logOutIcon} alt="Card" style={LogoutIconStyles} />
  )

  return (
    <div
    style={{
      backgroundColor: "#f2f4f8",
      height: "100vh", // Set the height to 100% of the viewport height
      padding: "20px",
      position: "fixed",
      display: "flex",
      flexDirection: "column", // Make the content stack vertically
      justifyContent: "space-between", // Add space between items
    }}
    >
      <div style={{ marginBottom: "58px", marginTop: "10px" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ marginLeft: "23px", height: "25px", width: "130px" }}
        />
      </div>
      <div style={{ marginBottom: "29px" }}>
        <button
          style={{
            color: currentSelection === "Companies" ? "#005DFF" : "#2E3A4B",
          }}
          className="buttonCompany"
          onClick={() => handleClick("Companies")}
        >
          {companyIcon}
          Companies
        </button>
      </div>
      <div style={{ marginBottom: "26px" }}>
        <button
          style={{
            color: currentSelection === "VR Training" ? "#005DFF" : "#2E3A4B",
          }}
          className="buttonVrTrainings"
          onClick={() => handleClick("VR Training")}
        >
          {VRIcon}
          VR Training
        </button>
      </div>
      <div style={{ marginBottom: "29px" }}>
        <button
          style={{
            color: currentSelection === "Plans" ? "#005DFF" : "#2E3A4B",
          }}
          className="buttonPlan"
          onClick={() => handleClick("Plans")}
        >
          {PlanIcon}
          Plans
        </button>
      </div>
      <div style={{ marginBottom: "29px" }}>
        <button
          style={{
            color: currentSelection === "Insights" ? "#005DFF" : "#2E3A4B",
          }}
          className="buttonInsight"
          onClick={() => handleClickInsight()}
        >
          {InsightIcon}
          Insights
        </button>
        </div>
        <div style={{ marginBottom: "29px" }}>
        <button
          style={{
            color: "#2E3A4B",
          }}
          className="buttonLogout"
          onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}
        >
          {LogoutIcon}
          Logout
        </button>
        </div>
    </div>
  );
};

export default Sidebar;
