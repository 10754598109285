import React, { useEffect, useState } from "react";
import axios from "axios";
import CompanyTable from "./CompanyTable";
import VRTraining from "./VRTraining";
import "./Dasgboard.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Sidebar from "./SideBar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";

function Dashboard() {
  const location = useLocation();
  let status;

  if (location.state) {
    status = location.state.status;
    console.log(status);
  } else {
    status = localStorage.getItem("currentSelection") || "Companies";
    console.log(status);
  }

  const [responseCompany, setResponseCompany] = useState(null);
  const [responseVR, setResponseVR] = useState(null);
  const [currentSelection, setCurrentSelection] = useState(status);
  const [visible, setVisible] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [showDialogAddFree, setShowDialogAddFree] = useState(false);
  const [showDialogAddStandard, setShowDialogAddStandard] = useState(false);
  const [showDialogAddPremium, setShowDialogAddPremium] = useState(false);
  const [response, setResponse] = useState([]);
  const [free, setFree] = useState(null);
  const [standard, setStandard] = useState(null);
  const [premium, setPremium] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [confiramtionFree, setConfirmationFree] = useState(false);
  const [confiramtionStandard, setConfirmationStandard] = useState(false);
  const [confiramtionPremium, setConfirmationPremium] = useState(false);
  const [confiramtionData, setConfirmationData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("currentSelection", currentSelection);
  }, [currentSelection]);

  const fetchData = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const responseCompany = await axios.get(
      process.env.REACT_APP_BACKEND_LINK + "/admin/get/table/" + id,
      config
    );
    const responseVR = await axios.get(
      process.env.REACT_APP_BACKEND_LINK + "/vr/get/trainings",
      config
    );
    const responseFree = await axios.get(
      process.env.REACT_APP_BACKEND_LINK + "/get/plans/free",
      config
    );
    const responseStandard = await axios.get(
      process.env.REACT_APP_BACKEND_LINK + "/get/plans/standart",
      config
    );
    const responsePremium = await axios.get(
      process.env.REACT_APP_BACKEND_LINK + "/get/plans/premium",
      config
    );
    setFree(responseFree.data.data);
    setStandard(responseStandard.data.data);
    setPremium(responsePremium.data.data);

    setResponseCompany(responseCompany.data.data);
    console.log(responseCompany.data.data);
    setResponseVR(responseVR.data.data);
    console.log(responseVR.data.data);
  };

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
    setCompanyName("");
    setEmail("");
  };

  const handleAddCompany = () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(
        process.env.REACT_APP_BACKEND_LINK + "/send/registration/link",
        {          
          email: email,
        },
        config
      )
      .then((responseCompany) => {
        console.log(responseCompany);
        if (responseCompany.status === 201) {
          toast.success("Company added successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Company added successfully.");
          hideDialog();
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.responseCompany.status === 401) {
          toast.error("This email already registered.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("This email already registered.");
        } else if (error.status === 402) {
          toast.error("Please provide a valid email address.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Please provide a valid email address.");
        } else if (error.status === 403) {
          toast.error("Company name already exists.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Company name already exists.");
        } else if (error.status === 400) {
          toast.error("Error status 400. Please contact support");
          console.log("Error status 400.");
        } else {
          toast.error("An error occurred while adding the company.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("An error occurred while adding the company.");
        }
      });
  };

  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
  };
  const deleteButtonFree = (rowData) => {
    const buttonStyle = {
      background: "none",
      border: "none",
      color: "#2E3A4B80",
      cursor: "pointer",
      padding: 0, // Remove any padding to make the cross fatter
      fontSize: "1.2rem", // Increase the font size for a fatter cross
      transition: "none", // Remove the hover transition
    };
    return (
      <Button
        icon="pi pi-times" // Using the times icon
        className="custom-button12"
        onClick={() => {
          setConfirmationFree(true);
          setConfirmationData(rowData.id);
        }}
      />
    );
  };
  const deleteButtonStandard = (rowData) => {
    const buttonStyle = {
      background: "none",
      border: "none",
      color: "#2E3A4B80",
      cursor: "pointer",
      padding: 0, // Remove any padding to make the cross fatter
      fontSize: "1.2rem", // Increase the font size for a fatter cross
      transition: "none", // Remove the hover transition
    };
    return (
      <Button
        icon="pi pi-times" // Using the times icon
        className="custom-button12"
        onClick={() => {
          setConfirmationStandard(true);
          setConfirmationData(rowData.id);
        }}
      />
    );
  };
  const deleteButtonPremium = (rowData) => {    

    return (
      <Button
        icon="pi pi-times"
        className="custom-button12"
        onClick={() => {
          setConfirmationPremium(true);
          setConfirmationData(rowData.id);
        }}
      />
    );
  };

  const handleDelete = (training, plan) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(
        process.env.REACT_APP_BACKEND_LINK +
          "/remove/training/toPlan/" +
          training +
          "/" +
          plan,
        config
      )
      .then((responseRemove) => {
        console.log(responseRemove);
        if (responseRemove.status === 200) {
          window.location.reload();
          toast.success("Training delete successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Training delete successfully.");
          fetchData();
        }
      });
  };
  const handleAdd = (training, plan) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .post(
        process.env.REACT_APP_BACKEND_LINK +
          "/add/training/toPlan/" +
          training +
          "/" +
          plan,
        config
      )
      .then((responseAdd) => {
        console.log(responseAdd);
        if (responseAdd.status === 200) {
          window.location.reload();
          toast.success("Training added successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          console.log("Training added successfully.");
          fetchData();
        }
      });
  };

  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          minWidth: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 1100,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection={currentSelection}
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h2 id="title" style={{ margin: 0, whiteSpace: "nowrap" }}>
              {currentSelection}
            </h2>
            {currentSelection === "Companies" && (
              <div style={{ marginLeft: "8px" }}>
                <Button
                  label="Send invite"
                  icon="pi pi-plus"
                  className="custom-button"
                  onClick={showDialog}
                />
              </div>
            )}
            {currentSelection === "VR Training" && (
              <div style={{ marginLeft: "18px" }}>
                <Button
                  label="Add training"
                  icon="pi pi-plus"
                  className="custom-button"
                  onClick={() => {
                    navigate("/training");
                  }}
                />
              </div>
            )}
            {currentSelection === "Plans"}
          </div>
          {currentSelection === "Companies" && responseCompany && (
            <CompanyTable data={responseCompany} />
          )}
          {currentSelection === "VR Training" && responseVR && (
            <VRTraining data={responseVR} />
          )}
          {currentSelection === "Plans" && (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "30%" }}>
                  <div className="name-container">
                    <h5>Free</h5>
                    <div>
                      <Button
                        label=<div class="buttonText">
                          <i
                            class="pi pi-plus"
                            style={{ fontWeight: "700" }}
                          ></i>{" "}
                          Add training
                        </div>
                        className="addTrainingButton"
                        onClick={async () => {
                          setShowDialogAddFree(true);
                          const trainingsAddedPlan = axios.get(
                            `${process.env.REACT_APP_BACKEND_LINK}/get/plans/free`
                          );
                          const allTrainings = axios.get(
                            `${process.env.REACT_APP_BACKEND_LINK}/vr/get/trainings`,
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          );

                          const [
                            PlanTrainingsResponse,
                            allTrainingsListResponse,
                          ] = await Promise.all([
                            trainingsAddedPlan,
                            allTrainings,
                          ]);

                          if (
                            PlanTrainingsResponse.status === 200 &&
                            allTrainingsListResponse.status === 200
                          ) {
                            const trainingsAdded =
                              PlanTrainingsResponse.data.data;
                            const trainingsAddedIds = trainingsAdded.map(
                              (item) => item.id
                            ); // Assuming trainings is an array of training IDs
                            const allTrainingsList =
                              allTrainingsListResponse.data.data; // Assuming trainingsList is an array of training objects
                            console.log("AAAAAAAAAAAAAAAAAAAAA");
                            console.log(trainingsAddedIds[0]);
                            console.log(
                              allTrainingsList.includes(trainingsAddedIds[0])
                            );
                            const filteredTrainingsList =
                              allTrainingsList.filter(
                                (training) =>
                                  !trainingsAddedIds.includes(training.id)
                              );

                            setResponse(filteredTrainingsList);
                            console.log(filteredTrainingsList);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <DataTable value={free} header={null} className="no-header">
  <Column className="ids" field="id" />
  <Column body={(rowData) => <div className="recordSettings">{rowData.name}</div>} />
  <Column body={deleteButtonFree} />
</DataTable>

                </div>
                <div style={{ width: "30%" }}>
                  <div className="name-container">
                    <h5>Standard</h5>
                    <div>
                      <Button
                          label=<div class="buttonText">
                          <i
                            class="pi pi-plus"
                            style={{ fontWeight: "700" }}
                          ></i>{" "}
                          Add training
                        </div>                        
                        className="addTrainingButton"
                        onClick={async () => {
                          setShowDialogAddStandard(true);
                          const trainingsAddedPlan = axios.get(
                            `${process.env.REACT_APP_BACKEND_LINK}/get/plans/standart`
                          );
                          const allTrainings = axios.get(
                            `${process.env.REACT_APP_BACKEND_LINK}/vr/get/trainings`,
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          );

                          const [
                            PlanTrainingsResponse,
                            allTrainingsListResponse,
                          ] = await Promise.all([
                            trainingsAddedPlan,
                            allTrainings,
                          ]);

                          if (
                            PlanTrainingsResponse.status === 200 &&
                            allTrainingsListResponse.status === 200
                          ) {
                            const trainingsAdded =
                              PlanTrainingsResponse.data.data;
                            const trainingsAddedIds = trainingsAdded.map(
                              (item) => item.id
                            ); // Assuming trainings is an array of training IDs
                            const allTrainingsList =
                              allTrainingsListResponse.data.data; // Assuming trainingsList is an array of training objects
                            console.log("AAAAAAAAAAAAAAAAAAAAA");
                            console.log(trainingsAddedIds[0]);
                            console.log(
                              allTrainingsList.includes(trainingsAddedIds[0])
                            );
                            const filteredTrainingsList =
                              allTrainingsList.filter(
                                (training) =>
                                  !trainingsAddedIds.includes(training.id)
                              );

                            setResponse(filteredTrainingsList);
                            console.log(filteredTrainingsList);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <DataTable value={standard} header={null} className="no-header">
  <Column className="ids" field="id" />
  <Column body={(rowData) => <div className="recordSettings">{rowData.name}</div>} />
  <Column body={deleteButtonStandard} />
</DataTable>
                </div>
                <div style={{ width: "30%" }}>
                  <div className="name-container">
                    <h5>Premium</h5>
                    <div>
                      <Button
                          label=<div class="buttonText">
                          <i
                            class="pi pi-plus"
                            style={{ fontWeight: "700" }}
                          ></i>{" "}
                          Add training
                        </div>
                        className="addTrainingButton"
                        onClick={async () => {
                          setShowDialogAddPremium(true);
                          const trainingsAddedPlan = axios.get(
                            `${process.env.REACT_APP_BACKEND_LINK}/get/plans/premium`
                          );
                          const allTrainings = axios.get(
                            `${process.env.REACT_APP_BACKEND_LINK}/vr/get/trainings`,
                            {
                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("token"),
                              },
                            }
                          );

                          const [
                            PlanTrainingsResponse,
                            allTrainingsListResponse,
                          ] = await Promise.all([
                            trainingsAddedPlan,
                            allTrainings,
                          ]);

                          if (
                            PlanTrainingsResponse.status === 200 &&
                            allTrainingsListResponse.status === 200
                          ) {
                            const trainingsAdded =
                              PlanTrainingsResponse.data.data;
                            const trainingsAddedIds = trainingsAdded.map(
                              (item) => item.id
                            ); // Assuming trainings is an array of training IDs
                            const allTrainingsList =
                              allTrainingsListResponse.data.data; // Assuming trainingsList is an array of training objects
                            console.log("AAAAAAAAAAAAAAAAAAAAA");
                            console.log(trainingsAddedIds[0]);
                            console.log(
                              allTrainingsList.includes(trainingsAddedIds[0])
                            );
                            const filteredTrainingsList =
                              allTrainingsList.filter(
                                (training) =>
                                  !trainingsAddedIds.includes(training.id)
                              );

                            setResponse(filteredTrainingsList);

                            console.log(filteredTrainingsList);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <DataTable value={premium} header={null} className="no-header">
  <Column className="ids" field="id" />
  <Column body={(rowData) => <div className="recordSettings">{rowData.name}</div>} />
  <Column body={deleteButtonPremium} />
</DataTable>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        visible={showDialogAddFree}
        onHide={() => {
          setShowDialogAddFree(false);
          setSearchTerm(""); // Clear the search term when the dialog is closed
        }}
        header={
          <div style={{ marginTop: "-25px" }}>Add training to Free plan</div>
        }
        modal
        style={{ width: "440px", height: "536px" }}
        closable={false}
        footer={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              label="Cancel"
              className="button-ablec"
              onClick={() => setShowDialogAddFree(false)}
            />
            <Button
              label="Add Company"
              onClick={() => handleAdd(selectedTraining, "free")}
              className="button-able"
            />
          </div>
        }
        dismissableMask={true}
        className="centered-dialog"
      >
        <div style={{ marginTop: "25px" }}>
          <InputText
            placeholder="Search trainings"
            value={searchTerm}
            style={{ width: "380px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div style={{ marginTop: "25px" }}>
          <div className="custom-training-name-title">Trainings</div>
          {response.length === 0 ? (
            <p>Trainings not found</p>
          ) : (
            <ul>
              {response
                .filter((training) =>
                  training.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) // Filter based on search term
                .map((training) => (
                  <h5 key={training.id} className="custom-training-name">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="selectedTraining"
                        value={training.id}
                        onChange={() => setSelectedTraining(training.id)}
                        style={{
                          width: "18px",
                          height: "18px",
                          padding: "2px",
                        }}
                      />
                      <span style={{ marginLeft: "7px", marginTop: "3.5px" }}>
                        {training.name}
                      </span>
                    </label>
                  </h5>
                ))}
            </ul>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={showDialogAddStandard}
        onHide={() => {
          setShowDialogAddStandard(false);
          setSearchTerm(""); // Clear the search term when the dialog is closed
        }}
        header={
          <div style={{ marginTop: "-25px" }}>
            Add training to Standard plan
          </div>
        }
        modal
        style={{ width: "440px", height: "536px" }}
        closable={false}
        footer={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              label="Cancel"
              className="button-ablec"
              onClick={() => setShowDialogAddStandard(false)}
            />
            <Button
              label="Add Company"
              onClick={() => handleAdd(selectedTraining, "standart")}
              className="button-able"
            />
          </div>
        }
        dismissableMask={true}
        className="centered-dialog"
      >
        <div style={{ marginTop: "25px" }}>
          <InputText
            placeholder="Search trainings"
            value={searchTerm}
            style={{ width: "380px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div style={{ marginTop: "25px" }}>
          <div className="custom-training-name-title">Trainings</div>
          {response.length === 0 ? (
            <p>Trainings not found</p>
          ) : (
            <ul>
              {response
                .filter((training) =>
                  training.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) // Filter based on search term
                .map((training) => (
                  <h5 key={training.id} className="custom-training-name">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="selectedTraining"
                        value={training.id}
                        onChange={() => setSelectedTraining(training.id)}
                        style={{
                          width: "18px",
                          height: "18px",
                          padding: "2px",
                        }}
                      />
                      <span style={{ marginLeft: "7px", marginTop: "3.5px" }}>
                        {training.name}
                      </span>
                    </label>
                  </h5>
                ))}
            </ul>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={showDialogAddPremium}
        onHide={() => {
          setShowDialogAddPremium(false);
          setSearchTerm(""); // Clear the search term when the dialog is closed
        }}
        header={
          <div style={{ marginTop: "-25px" }}>Add training to Premium plan</div>
        }
        modal
        style={{ width: "440px", height: "536px" }}
        closable={false}
        footer={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              label="Cancel"
              className="button-ablec"
              onClick={() => setShowDialogAddPremium(false)}
            />
            <Button
              label="Add Company"
              onClick={() => handleAdd(selectedTraining, "premium")}
              className="button-able"
            />
          </div>
        }
        dismissableMask={true}
        className="centered-dialog"
      >
        <div style={{ marginTop: "25px" }}>
          <InputText
            placeholder="Search trainings"
            value={searchTerm}
            style={{ width: "380px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div style={{ marginTop: "25px" }}>
          <div className="custom-training-name-title">Trainings</div>
          {response.length === 0 ? (
            <p>Trainings not found</p>
          ) : (
            <ul>
              {response
                .filter((training) =>
                  training.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) // Filter based on search term
                .map((training) => (
                  <h5 key={training.id} className="custom-training-name">
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="radio"
                        name="selectedTraining"
                        value={training.id}
                        onChange={() => setSelectedTraining(training.id)}
                        style={{
                          width: "18px",
                          height: "18px",
                          padding: "2px",
                        }}
                      />
                      <span style={{ marginLeft: "7px", marginTop: "3.5px" }}>
                        {training.name}
                      </span>
                    </label>
                  </h5>
                ))}
            </ul>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={confiramtionFree}
        onHide={() => {
          setConfirmationFree(false);
          setConfirmationData(null);
        }}
        header={
          <div style={{ marginTop: "-25px" }}>Add training to Premium plan</div>
        }
        style={{ width: "440px", height: "263px" }}
        closable={false}
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="Cancel"
              className="button-ablec"
              onClick={() => {
                setConfirmationFree(false);
                setConfirmationData(null);
              }}
            />
            <Button
              label="Delete"
              className="button-able"
              onClick={() => handleDelete(confiramtionData, "free")} // Wrap handleDelete in an anonymous arrow function
            />
          </div>
        }
      >
        <div style={{ marginTop: "25px" }}>
          <div className="custom-training-name-title">
            Are you sure you want to delete this training from Free plan?
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={confiramtionStandard}
        onHide={() => {
          setConfirmationStandard(false);
          setConfirmationData(null);
        }}
        header={
          <div style={{ marginTop: "-25px" }}>
            Add training to Standard plan
          </div>
        }
        style={{ width: "440px", height: "263px" }}
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="No"
              className="button-ablec"
              onClick={() => {
                setConfirmationStandard(false);
                setConfirmationData(null);
              }}
            />
            <Button
              label="Yes"
              className="button-able"
              onClick={() => handleDelete(confiramtionData, "standart")} // Wrap handleDelete in an anonymous arrow function
            />
          </div>
        }
      >
        <div style={{ marginTop: "25px" }}>
          <div className="custom-training-name-title">
            Are you sure you want to delete this training from Standard plan?
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={confiramtionPremium}
        onHide={() => {
          setConfirmationPremium(false);
          setConfirmationData(null);
        }}
        header={
          <div style={{ marginTop: "-25px" }}>Delete Training</div>
        }
        style={{ width: "440px", height: "263px" }}
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="No"
              className="button-ablec"
              onClick={() => {
                setConfirmationPremium(false);
                setConfirmationData(null);
              }}
            />
            <Button
              label="Yes"
              className="button-able"
              onClick={() => handleDelete(confiramtionData, "premium")} // Wrap handleDelete in an anonymous arrow function
            />
          </div>
        }
      >
        <div style={{ marginTop: "25px" }}>
          <div className="custom-training-name-title">
            Are you sure you want to delete this training from  plan?
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header={<div style={{ marginTop: "-25px" }}>Invite</div>}
        footer={
          <div
            className="p-dialog-footer"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="Cancel"
              className="button-ablec"
              onClick={hideDialog}
            />
            <Button
              label="Send invite"
              onClick={handleAddCompany}
              className="button-able"
            />
          </div>
        }
        dismissableMask={true}
        closable={false}
        className="centered-dialog"
        style={{ width: "440px", height: "273px" }}
      >
        <div className="p-fluid">
          {/* <div className="p-field">
            <div className="label-margin">
              <label htmlFor="companyName" className="label-form">
                Company name
              </label>
            </div>
            <InputText
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div> */}
          <div className="p-field">
            <div className="label-margin">
              <label htmlFor="email" className="label-form">
                Email
              </label>
            </div>
            <InputText
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* <div className="p-field">
      <div className='label-margin'>
      <label htmlFor="description" className='label-form'>Description</label>
      </div>
      <InputText
        id="description"        
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{  height: "110px" }}
      />
    </div> */}
        </div>
      </Dialog>
      <ToastContainer />
    </div>
  );
}

export default Dashboard;
