import React, { useEffect, useRef, useState, useMemo } from "react";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Legend,
  Bar,
  BarChart,
} from "recharts";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "./SideBar";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import axios from "axios";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./Insight.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Activity from "./../images/ic_activ.png";
import Monthly from "./../images/ic_monthly act.png";
import Devices from "./../images/ic_devices2.png";
import invited from "./../images/ic_VR.png";
import Orga from "./../images/ic_list.png";
import Annual from "./../images/annual.png";
import Invoice from "./../images/invoice.png";
import Users from "./../images/ic_users.png";
import DeactivatedImg from "./../images/deactivated.png";
import List from "./../images/list.png";
import VRTrainingTotal from "./../images/vr_training_total.png";
import VideoTotal from "./../images/360_video_total.png";
import BG from "./../images/bg.png";
import IC from "./../images/ic.png";
import ICU from "./../images/icu.png";
import Classrooms from "./../images/classroom.png";
import { TabView, TabPanel } from "primereact/tabview";
import Exclusive from "./../images/Excl TR.png";
import { Dialog } from "primereact/dialog";
function Insight() {
  const [currentSelection, setCurrentSelection] = useState("Insights");
  const [statsTotal, setStatsTotal] = useState("n/a");
  const [statsTotalPercentage, setStatsTotalPercentage] = useState("n/a");
  const [active, setActive] = useState("n/a");
  const [activePercentage, setActivePercentage] = useState("n/a");
  const [notVeified, setNotVerified] = useState("n/a");
  const [notVerifiedPercentage, setNotVerifiedPercentage] = useState("n/a");
  const [monthlyPayment, setMonthlyPayment] = useState("n/a");
  const [annualPayment, setAnnualPayment] = useState("n/a");
  const [invoicePayment, setInvoicePayment] = useState("n/a");
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [data2, setData2] = useState([]);
  const [days7, setDays7] = useState([]);
  const [days30, setDays30] = useState([]);
  const [days90, setDays90] = useState([]);
  const [organizationsTotalGraph, setOrganizationsTotalGraph] =
    useState("Last 30 days");
  const [devicesGraph, setDevicesGraph] = useState([]);
  const [devicesTotal, setDevicesTotal] = useState("n/a");
  const [devicesTotalPercentage, setDevicesTotalPercentage] = useState("n/a");
  const [totalUsers, setTotalUsers] = useState("n/a");
  const [totalUsersPercentage, setTotalUsersPercentage] = useState("n/a");
  const [licensesAllocated, setLicensesAllocated] = useState("n/a");
  const [licensesAllocatedPercentage, setLicensesAllocatedPercentage] =
    useState("n/a");
  const [deactivatedValue, setDeactivated] = useState("n/a");
  const [userTab, setUserTab] = useState([]);
  const [platformTab, setPlatformTab] = useState([]);
  const [VRtotal, setVRtotal] = useState("n/a");
  const [VRtotalPercentage, setVRtotalPercentage] = useState("n/a");
  const [videoTotal, setVideoTotal] = useState("n/a");
  const [videoTotalPercentage, setVideoTotalPercentage] = useState("n/a");
  const [classrooms, setClassrooms] = useState("n/a");
  const [classroomsPercentage, setClassroomsPercentage] = useState("n/a");
  const [VRTraining, setVRTraining] = useState([]);
  const [Video, setVideo] = useState([]);
  const [sessionTotal, setSessionTotal] = useState("n/a");
  const [percentChangeSessionTotal, setPercentChangeSessionTotal] =
    useState("n/a");
  const [sessionVRTotal, setSessionVRTotal] = useState("n/a");
  const [percentChangeSessionVRTotal, setPercentChangeSessionVRTotal] =
    useState("n/a");
  const [sessionWebTotal, setSessionWebTotal] = useState("n/a");
  const [sessionTrainingTotal, setSessionTrainingTotal] = useState("n/a");
  const [
    percentChangeSessionTrainingTotal,
    setPercentChangeSessionTrainingTotal,
  ] = useState("n/a");
  const [getUnfinishedVrSessinsTotal, setGetUnfinishedVrSessinsTotal] =
    useState("n/a");
  const [sessionVideoTotal, setSessionVideoTotal] = useState("n/a");
  const [sessionVideoVR, setSessionVideoVR] = useState("n/a");
  const [sessionVideoWeb, setSessionVideoWeb] = useState("n/a");
  const [percentChangeVideoSessionTotal, setPercentChangeVideoSessionTotal] =
    useState("n/a");
  const [sessionsVRWebonly, setSessionsVRWebonly] = useState([]);
  const [videoSessions, setVideoSessions] = useState([]);
  const [VRTrainingSessions, setVRTrainingSessions] = useState([]);
  const [avgTimeSpentDays, setAvgTimeSpentDays] = useState("n/a");
  const [avgTimeSpentHours, setAvgTimeSpentHours] = useState("n/a");
  const [percentChangeTimeSpent, setPercentChangeTimeSpent] = useState("n/a");
  const [avgUsers, setAvgUsers] = useState("n/a");
  const [percentChangeUsers, setPercentChangeUsers] = useState("n/a");
  const [avgDevice, setAvgDevice] = useState("n/a");
  const [percentChangeDevice, setPercentChangeDevice] = useState("n/a");
  const [avgTimeVRvsWEB, setAvgTimeVRvsWEB] = useState([]);
  const [avgTime7days, setAvgTime7days] = useState([]);
  const [avgTime30days, setAvgTime30days] = useState([]);
  const [avgTime90days, setAvgTime90days] = useState([]);
  const [avgTime365days, setAvgTime365days] = useState([]);
  const [vrTrainingDownloadsTotal, setVrTrainingDownloadsTotal] = useState("n/a");
  const [newData2, setNewData2 ] = useState([]);
  
  const [totalDownloadsVideo, setTotalDownloadsVideo] = useState("n/a");
  const [videoUploadsTotal, setVideoUploadsTotal] = useState("n/a");

  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  const getMonthYearLabel = (month) => {
    return month;
  };

  const getMonthYearLabel2 = (dateString) => {
    const date = new Date(dateString);
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      date
    );
    const year = date.getFullYear();
    return `${month} ${year}`;
  };
  const getMonthYearLabel3 = (dateString) => {
    const date = new Date(dateString);
    const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
      date
    );
    const day = date.getDate();
    return `${day} ${month}`;
  };

  // /

  const options = [
    { label: "Last 7 days", value: "Last 7 days" },
    { label: "Last 30 days", value: "Last 30 days" },
    { label: "Last 90 days", value: "Last 90 days" },
    { label: "Last 365 days", value: "Last 365 days" },
  ];

  function Container1() {
    return (
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ marginTop: "15px", minWidth: "1018px" }}
      >
        <AreaChart
          data={days7}
          margin={{ top: 10, right: 30, left: 0, bottom: 40 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="60%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(dateString) => getMonthYearLabel3(dateString)}
            interval={0}
            tick={{ angle: -45, textAnchor: "end" }}
          />

          <YAxis />
          <Tooltip
            formatter={(value, name, props) => [
              value,
              `Organizations`,
              `${getMonthYearLabel(props.payload.month)}`,
            ]}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#82ca9d"
            fill={`url(#colorUv)`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  function Container2() {
    return (
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ marginTop: "15px", minWidth: "1018px" }}
      >
        <AreaChart
          data={days30}
          margin={{ top: 10, right: 30, left: 0, bottom: 40 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="60%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(dateString) => getMonthYearLabel3(dateString)}
            interval={2}
            tick={{ angle: -45, textAnchor: "end" }}
          />

          <YAxis />
          <Tooltip
            formatter={(value, name, props) => [
              value,
              `Organizations`,
              `${getMonthYearLabel(props.payload.month)}`,
            ]}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#82ca9d"
            fill={`url(#colorUv)`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  function Container3() {
    return (
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ marginTop: "15px", minWidth: "1018px" }}
      >
        <AreaChart
          data={days90}
          margin={{ top: 10, right: 30, left: 0, bottom: 40 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="60%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(dateString) => getMonthYearLabel3(dateString)}
            interval={3}
            tick={{ angle: -45, textAnchor: "end" }}
          />

          <YAxis />
          <Tooltip
            formatter={(value, name, props) => [
              value,
              `Organizations`,
              `${getMonthYearLabel(props.payload.month)}`,
            ]}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#82ca9d"
            fill={`url(#colorUv)`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  function Container4() {
    return (
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ marginTop: "15px", minWidth: "1018px" }}
      >
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 40 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="60%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(dateString) => getMonthYearLabel2(dateString)}
            interval={30}
            tick={{ angle: -45, textAnchor: "end" }}
          />

          <YAxis />
          <Tooltip
            formatter={(value, name, props) => [
              value,
              `Organizations`,
              `${getMonthYearLabel(props.payload.month)}`,
            ]}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#82ca9d"
            fill={`url(#colorUv)`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
  function LastContainer({ data, tickFormatter, interval }) {
    return (
      <ResponsiveContainer
        width="100%"
        height={323}
        style={{ marginTop: "15px" }}
      >
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 40 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="60%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="60%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(dateString) => tickFormatter(dateString)}
            interval={interval}
            tick={{ angle: -45, textAnchor: "end" }}
          />
          <YAxis />
          <Tooltip
            formatter={(value, name, props) => [
              value,
              name === "count" ? "Web" : "Vr", // Display 'Web' for count and 'Vr' for count2
              `${getMonthYearLabel(props.payload.month)}`,
            ]}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke="#82ca9d"
            fill={`url(#colorUv)`}
          />
          <Area
            type="monotone"
            dataKey="count2"
            stroke="#8884d8"
            fill={`url(#colorPv)`}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
  function LastContainer1() {
    return (
      <LastContainer
        data={avgTime7days}
        tickFormatter={getMonthYearLabel3}
        interval={0}
      />
    );
  }

  function LastContainer2() {
    return (
      <LastContainer
        data={avgTime30days}
        tickFormatter={getMonthYearLabel3}
        interval={2}
      />
    );
  }

  function LastContainer3() {
    return (
      <LastContainer
        data={avgTime90days}
        tickFormatter={getMonthYearLabel3}
        interval={3}
      />
    );
  }

  function LastContainer4() {
    return (
      <LastContainer
        data={avgTime365days}
        tickFormatter={getMonthYearLabel2}
        interval={30}
      />
    );
  }

  const fetchData = async () => {
    try {
      const endpoints = [
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/total/company`,
          handler: (data) => {
            setStatsTotal(data.data.total);
            setStatsTotalPercentage(data.data.percentChangeCompany);
            setActive(data.data.active);
            setActivePercentage(data.data.activePercentChange);
            setNotVerified(data.data.notVerified);
            setNotVerifiedPercentage(data.data.notVerifiedPercentChange);
            setMonthlyPayment(data.data.monthly);
            setAnnualPayment(data.data.annual);
            setInvoicePayment(data.data.invoice);
            const temp = [
              { name: "Paid", value: data.data.paid },
              { name: "Free", value: data.data.free },
            ];
            const temp2 = [
              { name: "Web only", value: data.data.web },
              { name: "VR only", value: data.data.vr },
            ];
            setData2(temp);
            setNewData(temp2);
          },
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/company/count/year`,
          handler: (data) => setData(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/company/count/seven/days`,
          handler: (data) => setDays7(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/company/count/thirty/days`,
          handler: (data) => setDays30(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/company/count/ninety/days`,
          handler: (data) => setDays90(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/count/devices/by/platform`,
          handler: (data) => {
            let forPie = [
              { name: "Pico", value: data.data.pico },
              { name: "Steam PC", value: data.data.windows },
              { name: "Quest", value: data.data.meta },
            ];
            setDevicesGraph(forPie);
            setDevicesTotal(data.data.total);
            setDevicesTotalPercentage(data.data.percentChange);
          },
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/users/stat`,
          handler: (data) => {
            setTotalUsers(data.data.totalUsers);
            setTotalUsersPercentage(data.data.percentChange);
            setLicensesAllocated(data.data.licenseAllocated);
            setLicensesAllocatedPercentage(data.data.percentChangeLicense);
            setDeactivated(data.data.deactivatedUsersCount);
            let UserTab = [
              { name: "Free", value: data.data.freeCompanyUserCount },
              { name: "Paid", value: data.data.paidCompanyUserCount },
            ];
            setUserTab(UserTab);
            let PlatformTab = [
              { name: "VR only", value: data.data.userApp },
              { name: "Web only", value: data.data.userWeb },
            ];
            setPlatformTab(PlatformTab);
          },
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/content/info`,
          handler: (data) => {
            setVrTrainingDownloadsTotal(data.data.vrTrainingDownloadsTotal);
            let newTemp2 = [
              { name: "Web", value: data.data.videoWebDownloadsTotal },
              { name: "VR", value: data.data.videoVrDownloadsTotal },
            ];
            setNewData2(newTemp2);
            setTotalDownloadsVideo(data.data.totalDownloadsVideo);
            setVideoUploadsTotal(data.data.videoUploadsTotal);
            setVRtotal(data.data.vRTrainingTotal);
            setVRtotalPercentage(data.data.percentChangeTrainings);
            setVideoTotal(data.data.videoTotal);
            setVideoTotalPercentage(data.data.percentChangeVideos);
            setClassrooms(data.data.classrooms);
            setClassroomsPercentage(data.data.percentChangeClassrooms);
            let VRTraining = [
              { name: "Free", value: data.data.vRTrainingFree },
              { name: "Paid", value: data.data.vRTrainingPaid },
              { name: "Exclusive", value: data.data.vRTrainingExclusive },
            ];
            setVRTraining(VRTraining);
            let Video = [
              { name: "Metaenga", value: data.data.videoMetaenga },
              { name: "Organizations", value: data.data.videoOrg },
            ];
            setVideo(Video);
          },
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/info/sessions`,
          handler: (data) => {
            if (data && data.data) {
              setSessionTotal(data.data.sessionTotal || "n/a");
              setPercentChangeSessionTotal(
                data.data.percentChangeSessionTotal || "n/a"
              );
              setSessionVRTotal(data.data.sessionVRTotal || "n/a");
              setPercentChangeSessionVRTotal(
                data.data.percentChangeSessionVRTotal || "n/a"
              );
              setSessionWebTotal(data.data.sessionWebTotal || "n/a");
              setSessionTrainingTotal(data.data.sessionTrainingTotal || "n/a");
              setPercentChangeSessionTrainingTotal(
                data.data.percentChangeSessionTrainingTotal || "n/a"
              );
              setGetUnfinishedVrSessinsTotal(
                data.data.getUnfinishedVrSessinsTotal || "n/a"
              );
              setSessionVideoTotal(data.data.sessionVideoTotal || "n/a");
              setSessionVideoVR(data.data.sessionVideoVR || "n/a");
              setSessionVideoWeb(data.data.sessionVideoWeb || "n/a");
              setPercentChangeVideoSessionTotal(
                data.data.percentChangeVideoSessionTotal || "n/a"
              );
            } else {
              setSessionTotal("n/a");
              setPercentChangeSessionTotal("n/a");
              setSessionVRTotal("n/a");
              setPercentChangeSessionVRTotal("n/a");
              setSessionWebTotal("n/a");
              setSessionTrainingTotal("n/a");
              setPercentChangeSessionTrainingTotal("n/a");
              setGetUnfinishedVrSessinsTotal("n/a");
              setSessionVideoTotal("n/a");
              setSessionVideoVR("n/a");
              setSessionVideoWeb("n/a");
              setPercentChangeVideoSessionTotal("n/a");
            }
            let SessionsVRWebonly = [
              { name: "Web", value: data.data.sessionWebTotal },
              { name: "VR", value: data.data.sessionVRTotal },
            ];
            setSessionsVRWebonly(SessionsVRWebonly);
            let VideoSessions = [
              { name: "Web", value: data.data.sessionVideoWeb },
              { name: "VR", value: data.data.sessionVideoVR },
            ];
            setVideoSessions(VideoSessions);
          },
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/count/sessions/by/training`,
          handler: (data) => setVRTrainingSessions(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/average/metrics`,
          handler: (data) => {
            setAvgTimeSpentDays(data.data.avgTimeSpentDays);
            setAvgTimeSpentHours(data.data.avgTimeSpentHours);
            setPercentChangeTimeSpent(data.data.percentChangeTimeSpent);
            setAvgUsers(data.data.avgUsers);
            setPercentChangeUsers(data.data.percentChangeUsers);
            setAvgDevice(data.data.avgHeadsets);
            setPercentChangeDevice(data.data.percentChangeHeadsets);
            let AvgTimeVRvsWEB = [
              { name: "Web", value: data.data.avgTimeSpentWeb },
              { name: "VR", value: data.data.avgTimeSpentVr },
            ];
            setAvgTimeVRvsWEB(AvgTimeVRvsWEB);
          },
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/average/time/spent/seven/days`,
          handler: (data) => setAvgTime7days(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/average/time/spent/thirty/days`,
          handler: (data) => setAvgTime30days(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/average/time/spent/ninety/days`,
          handler: (data) => setAvgTime90days(data.data),
        },
        {
          url: `${process.env.REACT_APP_STATISTICS_LINK}/get/average/time/spent/year`,
          handler: (data) => setAvgTime365days(data.data),
        },
      ];
  
      const requests = endpoints.map((endpoint) =>
        axios.get(endpoint.url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
      );
  
      const results = await Promise.allSettled(requests);
  
      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          endpoints[index].handler(result.value.data);
        } else {
          console.log(`Error fetching data from ${endpoints[index].url}:`, result.reason);
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Error fetching data.");
    }
  };
  
  const handleSelectionChange = (selection) => {
    setCurrentSelection(selection);
    navigate("/");
  };
  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  };
  VRTrainingSessions.forEach((item) => {
    item.color = getRandomColor();
  });
  return (
    <div style={{ display: "flex", margin: 0 }}>
      <div
        style={{
          width: "230px",
          height: "100vh",
          backgroundColor: "#f2f4f8",
          padding: "10px",
          margin: 0,
          zIndex: 1100,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Sidebar
          UIselection="Insights"
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div style={{ width: "80%", padding: "10px", marginLeft: "240px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h2
              id="title"
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                marginLeft: "10px",
                
              }}
            >
              {currentSelection}
            </h2>
            <h2
              id="title"
              style={{ margin: 0, whiteSpace: "nowrap", marginLeft: "41px" }}
            ></h2>
          </div>
          <TabView>
            <TabPanel header="Organizations">
              <div class="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={Orga}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Organizations total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{statsTotal}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            statsTotalPercentage > 0
                              ? "#1FB155"
                              : statsTotalPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {statsTotalPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={Activity}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Active
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{active}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            activePercentage > 0
                              ? "#1FB155"
                              : activePercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {activePercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={invited}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Not verified
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{notVeified}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            notVerifiedPercentage > 0
                              ? "#1FB155"
                              : notVerifiedPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {notVerifiedPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  height: "474px",
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "330px",
                  marginTop: "20px",
                  position: "relative",
                }}
              >
                <div className="row">
                  <div
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Organizations Total
                  </div>
                  <Dropdown
                    style={{
                      width: "200px",
                      marginLeft: "auto",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                    placeholder={organizationsTotalGraph}
                    options={options}
                    onChange={(option) => {
                      setOrganizationsTotalGraph(option.value);
                    }}
                  />
                </div>
                {organizationsTotalGraph === "Last 7 days" && <Container1 />}
                {organizationsTotalGraph === "Last 30 days" && <Container2 />}
                {organizationsTotalGraph === "Last 90 days" && <Container3 />}
                {organizationsTotalGraph === "Last 365 days" && <Container4 />}
              </div>
              <div className="row">
                
                <div className="row">
                  <div
                    style={{
                      border: "1px solid #c0c0c0",
                      height: "104px",
                      width: "100%",
                      borderRadius: "6px",
                      minWidth: "330px",
                      marginTop: "20px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={Monthly}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        width: "36px",
                      }}
                    />
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontWeight: "500",
                      }}
                    >
                      Monthly payment
                    </div>
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      <div class="row">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                          }}
                        >
                          {monthlyPayment}{" "}
                          <div
                            style={{
                              color: "rgba(46, 58, 75, 0.7)",
                              fontSize: "18px",
                              marginTop: "6px",
                            }}
                          >
                            org.
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "5px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #c0c0c0",
                      height: "104px",
                      width: "100%",
                      borderRadius: "6px",
                      minWidth: "330px",
                      marginTop: "20px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={Annual}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        width: "36px",
                      }}
                    />
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontWeight: "500",
                      }}
                    >
                      Annually payment
                    </div>
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      <div class="row">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                          }}
                        >
                          {annualPayment}{" "}
                          <div style={{
                              color: "rgba(46, 58, 75, 0.7)",
                              fontSize: "18px",
                              marginTop: "6px",
                            }}>
                            org.
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "5px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #c0c0c0",
                      height: "104px",
                      width: "100%",
                      borderRadius: "6px",
                      minWidth: "330px",
                      marginTop: "20px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={Invoice}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        width: "36px",
                      }}
                    />
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontWeight: "500",
                      }}
                    >
                      Invoice payment
                    </div>
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      <div class="row">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                          }}
                        >
                          {invoicePayment}{" "}
                          <div style={{
                              color: "rgba(46, 58, 75, 0.7)",
                              fontSize: "18px",
                              marginTop: "6px",
                            }}>
                            org.
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "5px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "204%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    Subscription types
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={data2}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#A4CEDB"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#C3E3DC" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = data2.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "204%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    Registrations: Web vs VR
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={newData}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#EDD48B"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#BDDCA5" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = newData.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Users">
              <div class="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={Users}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Users total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{totalUsers}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            totalUsersPercentage > 0
                              ? "#1FB155"
                              : totalUsersPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {totalUsersPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={List}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Licenses allocated
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{licensesAllocated}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            licensesAllocatedPercentage > 0
                              ? "#1FB155"
                              : licensesAllocatedPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {licensesAllocatedPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={DeactivatedImg}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Deactivated
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{deactivatedValue}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    User subscriprion types
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={userTab}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#1F78B4"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#56ACE6" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = userTab.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    Platform usage
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={platformTab}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#EBBED1"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#ACAFEC" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = platformTab.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Devices">
              <div className="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "204%",
                    borderRadius: "6px",
                    minWidth: "675px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    Device usage
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={devicesGraph}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#EDAA98"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#FEEB99" />
                        <Cell fill="#C1DCBA" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = devicesGraph.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="column">
                  <div
                    style={{
                      border: "1px solid #c0c0c0",
                      height: "104px",
                      width: "100%",
                      borderRadius: "6px",
                      minWidth: "330px",
                      position: "relative",
                      marginTop: "20px",
                    }}
                  >
                    <img
                      src={Devices}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "20px",
                        width: "36px",
                      }}
                    />
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontWeight: "500",
                      }}
                    >
                      Devices total
                    </div>
                    <div
                      class="column"
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      <div class="row">
                        <div>{devicesTotal}</div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            color:
                              devicesTotalPercentage > 0
                                ? "#1FB155"
                                : devicesTotalPercentage < 0
                                ? "#E82F2F"
                                : "black",
                          }}
                        >
                          {devicesTotalPercentage}%
                        </div>
                        <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                          the last 30 days
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Content">
              <div class="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={VRTrainingTotal}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    VR Training total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{VRtotal}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            VRtotalPercentage > 0
                              ? "#1FB155"
                              : VRtotalPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {VRtotalPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={BG}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    VR Training downloads total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{vrTrainingDownloadsTotal}</div>
                    </div>
                    {/* <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            videoTotalPercentage > 0
                              ? "#1FB155"
                              : videoTotalPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {videoTotalPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={Classrooms}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Classrooms
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{classrooms}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            classroomsPercentage > 0
                              ? "#1FB155"
                              : classroomsPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {classroomsPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style={{marginTop: "20px",}}>
              <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    
                    position: "relative",
                  }}
                >
                  <img
                    src={VideoTotal}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    360 Video total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{videoTotal}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            videoTotalPercentage > 0
                              ? "#1FB155"
                              : videoTotalPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {videoTotalPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    
                    position: "relative",
                  }}
                >
                  <img
                    src={IC}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    360 Video downloads total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{totalDownloadsVideo}</div>
                    </div>
                    {/* <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            videoTotalPercentage > 0
                              ? "#1FB155"
                              : videoTotalPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {videoTotalPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <img
                    src={ICU}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      width: "36px",
                    }}
                  />
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    360 Video uploads total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{videoUploadsTotal}</div>
                    </div>
                    {/* <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            classroomsPercentage > 0
                              ? "#1FB155"
                              : classroomsPercentage < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {classroomsPercentage}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    VR Training
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={VRTraining}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#C5D9AB"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#FFCC9C" />
                        <Cell fill="#FEEEB9" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = VRTraining.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    360 Video
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={Video}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#B3CDE3"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#FBB4AE" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = Video.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="row">
                
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "50%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    360 Video downloads: Web vs Vr
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={newData2}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#FFE5E5"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#E0AED0" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = newData2.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Sessions">
              <div class="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "230px",
                    position: "relative",
                  }}
                >
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Session total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{sessionTotal}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            percentChangeSessionTotal > 0
                              ? "#1FB155"
                              : percentChangeSessionTotal < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {percentChangeSessionTotal}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "230px",
                    position: "relative",
                  }}
                >
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Session VR app total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{sessionVRTotal}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            percentChangeSessionVRTotal > 0
                              ? "#1FB155"
                              : percentChangeSessionVRTotal < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {percentChangeSessionVRTotal}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "230px",
                    position: "relative",
                  }}
                >
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Session VR training total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{sessionTrainingTotal}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            percentChangeSessionTrainingTotal > 0
                              ? "#1FB155"
                              : percentChangeSessionTrainingTotal < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {percentChangeSessionTrainingTotal}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "230px",
                    position: "relative",
                  }}
                >
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Session 360 video total
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{sessionVideoTotal}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            percentChangeVideoSessionTotal > 0
                              ? "#1FB155"
                              : percentChangeVideoSessionTotal < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {percentChangeVideoSessionTotal}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    Sessions: VR only vs Web only
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={sessionsVRWebonly}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#D1D5EB"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#DDCFF7" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = sessionsVRWebonly.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "352px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    360 Video sessions: VR vs Web
                  </div>

                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={videoSessions}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#ADD5DD"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#CACDBA" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = videoSessions.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #c0c0c0",
                  minHeight: "600px", // Set minHeight instead of height
                  width: "100%",
                  borderRadius: "6px",
                  minWidth: "675px",
                  marginTop: "20px",
                  position: "relative",
                  display: "flex", // Use display: flex
                  flexDirection: "column", // Column direction for flex
                }}
              >
                <div
                  style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                >
                  VR Training sessions
                </div>
                <div className="row">
                  <ResponsiveContainer width="100%" height={550}>
                    <BarChart
                      width="100%"
                      height={400}
                      data={VRTrainingSessions}
                      layout="vertical"
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" hide />
                      <YAxis type="category" dataKey="label" />
                      <Tooltip
                        formatter={(value, name, props) => [
                          value,
                          `${props.payload.fullname}`,
                          `Category: ${name}`,
                        ]}
                      />

                      <Bar dataKey="value">
                        {VRTrainingSessions.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                  <div
                    style={{
                      marginTop: "20px",
                      width: "50%",
                      marginBottom: "20px",
                    }}
                  >
                    {VRTrainingSessions.map((item, index) => (
                      <div style={{ rowGap: "10px" }} key={index}>
                        <span
                          style={{
                            backgroundColor: item.color,
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            marginRight: "5px", // Add margin to the right of the color block
                            marginTop: "5px", // Adjust vertical alignment of the color block
                            verticalAlign: "middle", // Adjust vertical alignment of the color block
                          }}
                        ></span>
                        <span
                          style={{
                            lineHeight: "20px",
                            verticalAlign: "middle",
                          }}
                        >
                          {`Tr ${index + 1}: ${item.fullname}`}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Avarage metrics">
              <div class="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Avg. Time spent
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div>{avgTimeSpentDays}</div>
                      <div
                        style={{
                          fontSize: "14px",
                          marginTop: "5px",
                          color: "rgba(46, 58, 75, 0.7)",
                        }}
                      >
                        days
                      </div>
                      <div>{avgTimeSpentHours}</div>
                      <div
                        style={{
                          fontSize: "14px",
                          marginTop: "5px",
                          color: "rgba(46, 58, 75, 0.7)",
                        }}
                      >
                        hours
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            percentChangeTimeSpent > 0
                              ? "#1FB155"
                              : percentChangeTimeSpent < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {percentChangeTimeSpent}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Avg. organizations users
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{avgUsers}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            percentChangeUsers > 0
                              ? "#1FB155"
                              : percentChangeUsers < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {percentChangeUsers}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "104px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "330px",
                    position: "relative",
                  }}
                >
                  <div
                    class="column"
                    style={{ padding: "10px 10px 0px 20px", fontWeight: "500" }}
                  >
                    Avg. organizations headsets
                  </div>
                  <div
                    class="column"
                    style={{
                      padding: "10px 10px 0px 20px",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    <div class="row">
                      <div>{avgDevice}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          color:
                            percentChangeDevice > 0
                              ? "#1FB155"
                              : percentChangeDevice < 0
                              ? "#E82F2F"
                              : "black",
                        }}
                      >
                        {percentChangeDevice}%
                      </div>
                      <div style={{ color: "rgba(46, 58, 75, 0.7)" }}>
                        the last 30 days
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "5px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "400px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div className="row">
                    <div
                      style={{
                        padding: "10px 10px 0px 20px",
                        fontWeight: "500",
                      }}
                    >
                      Avg. Time spent: VR vs Web (min)
                    </div>
                    <Dropdown
                      style={{
                        width: "200px",
                        marginLeft: "auto",
                        marginRight: "20px",
                        marginTop: "10px",
                      }}
                      placeholder={organizationsTotalGraph}
                      options={options}
                      onChange={(option) => {
                        setOrganizationsTotalGraph(option.value);
                      }}
                    />
                  </div>
                  {organizationsTotalGraph === "Last 7 days" && (
                    <LastContainer1 />
                  )}
                  {organizationsTotalGraph === "Last 30 days" && (
                    <LastContainer2 />
                  )}
                  {organizationsTotalGraph === "Last 90 days" && (
                    <LastContainer3 />
                  )}
                  {organizationsTotalGraph === "Last 365 days" && (
                    <LastContainer4 />
                  )}
                </div>
                <div
                  style={{
                    border: "1px solid #c0c0c0",
                    height: "400px",
                    width: "100%",
                    borderRadius: "6px",
                    minWidth: "300px",
                    marginTop: "20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{ padding: "10px 0px 0px 20px", fontWeight: "500" }}
                  >
                    Avg. Sessions: VR vs Web (min)
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={avgTimeVRvsWEB}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        innerRadius={35}
                        fill="#EBBED1"
                        label={{ fill: "black" }}
                      >
                        <Cell fill="#ACAFEC" />
                      </Pie>
                      <Tooltip
                          formatter={(value, name, props) => {
                            const total = avgTimeVRvsWEB.reduce((acc, cur) => acc + cur.value, 0);
                            const percentage = `${((value / total) * 100).toFixed(2)}%`;
                            return [
                              <div key="tooltipContent">{name}: {value}
                                <br />
                                Percentage: {percentage}     
                              </div>
                            ]
                          }}
                      />
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        height={36}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
export default Insight;
