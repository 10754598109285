import React, { useEffect, useRef, useState } from "react";
import "./Dasgboard.css";
import "./VRtraining.css";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import DropzoneUploader from "react-dropzone-uploader";
import axios from "axios";

const VRTraining = ({ data }) => {
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(true);
  const overlayPanelRef = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogChunk, setShowDialogChunk] = useState(false);
  const [showDialogUnassign, setShowDialogUnassign] = useState(false);
  const [showDialogAssign, setShowDialogAssign] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [responseUsed, setResponseUsed] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const token = localStorage.getItem("token");
  const [isValidFile, setIsValidFile] = useState(true);
  const [publicity, setPublicity] = useState(0); // Default to public (1)
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // simulate loading delay

    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []); // set the loading state to false after 0.400 seconds

  useEffect(() => {
    if (showDialogAssign) {
      document.body.classList.add("dialog-open");
    } else {
      document.body.classList.remove("dialog-open");
    }
  }, [showDialogAssign]);

  const overlayPanelContent = (
    <div className="button-box">
      <button onClick={() => handleEdit(rowData)} className="button-panel">
        Edit
      </button>
      <button onClick={() => handleUnassign(rowData)} className="button-panel">
        Unassign
      </button>
      <button onClick={() => handleChunk(rowData)} className="button-panel">
        Update chunk
      </button>
      <button onClick={() => handlePoster(rowData)} className="button-panel">
        Add poster
      </button>
      <button onClick={() => handleAssign(rowData)} className="button-panel">
        Assign
      </button>
      <button onClick={() => handleDelete(rowData)} className="button-panel">
        Delete
      </button>
    </div>
  );
  const onUpload = (file) => {
    setIsLoading(true);
    console.log(file.file);
    // upload file logic here...
    setFile(file.file);
    setIsLoading(false);
  };

  const Preview = ({ meta, fileWithMeta: { remove } }) => {
    const handleRemove = (event) => {
      event.stopPropagation();
      remove();
    };

    return (
      <div className="preview">
        <div className="flex flex-row gap-2 align-items-center">
          <i className="pi pi-file" style={{ fontSize: "1.2rem" }}></i>
          <p>{meta.name}</p>
          <button
            className="pi pi-trash "
            style={{
              border: "none",
              background: "none",
              marginLeft: "200px",
              fontSize: "1.2rem",
            }}
            onClick={handleRemove}
          ></button>
        </div>
      </div>
    );
  };
  const handleUnassign = async (rowData) => {
    console.log("Assign clicked for row data:", rowData);
    try {
      const fetchCompanies = axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/companies/byTraining/${rowData.id}`
      );
      const fetchCompanyList = axios.get(
        `${
          process.env.REACT_APP_BACKEND_LINK
        }/getCompanyList/${localStorage.getItem("id")}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      const [companiesResponse, companyListResponse] = await Promise.all([
        fetchCompanies,
        fetchCompanyList,
      ]);

      if (
        companiesResponse.status === 200 &&
        companyListResponse.status === 200
      ) {
        const companies = companiesResponse.data.companies;
        console.log("companies", companies);  
        const companyList = companyListResponse.data.companyList;
        console.log("companyList", companyList);
        const result = companyList.map(({ id, companyName, userEmail }) => ({
          id,
          name: companyName,
          mail: userEmail,
          used: companies.some((company) => company.userEmail === userEmail)
            ? 1
            : 0,
        }));

        console.log(result);

        setResponse(result);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }

    setRowData(rowData);
    setShowDialogUnassign(true);
  };
  const handleCloseDialogAssign = () => {
    setSelectedCompanies([]);
    setSelectedPlatform(null);
    setPublicity(1);
    setShowDialogAssign(false);
    setResponse(null);
    setResponseUsed(null);
  };

  const handleCloseDialogUnassign = () => {
    setSelectedCompanies([]);
    setSelectedPlatform(null);
    setShowDialogUnassign(false);
    setResponse(null);
    setResponseUsed(null);
  };
  const handleCloseDialogDelete = () => {
    setSelectedCompanies([]);
    setSelectedPlatform(null);
    setShowDialogDelete(false);
    setResponse(null);
    setResponseUsed(null);
  };
  const handleAssign = async (rowData) => {
    console.log("Assign clicked for row data:", rowData);

    try {
      const fetchCompanies = axios.get(
        `${process.env.REACT_APP_BACKEND_LINK}/get/companies/byTraining/${rowData.id}`
      );
      const fetchCompanyList = axios.get(
        `${
          process.env.REACT_APP_BACKEND_LINK
        }/getCompanyList/${localStorage.getItem("id")}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      const [companiesResponse, companyListResponse] = await Promise.all([
        fetchCompanies,
        fetchCompanyList,
      ]);

      console.log("companyListResponse", companyListResponse);

      if (
        companiesResponse.status === 200 &&
        companyListResponse.status === 200
      ) {
        const companies = companiesResponse.data.companies;
        const companyList = companyListResponse.data.companyList;
        const result = companyList.map(({ id, companyName, userEmail }) => ({
          id,
          name: companyName,
          mail: userEmail,
          used: companies.some((company) => company.userEmail === userEmail)
            ? 1
            : 0,
        }));

        console.log(result);

        setResponse(result);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }

    setRowData(rowData);
    setShowDialogAssign(true);
  };

  const handleDelete = async (rowData) => {
    console.log("Delete clicked for row data:", rowData);
    // const response = await axios.post(`${process.env.REACT_APP_BACKEND_LINK}/vr/delete/training`, {"id":rowData.id}, {
    //   headers: {
    //     Authorization: 'Bearer ' + token,
    //   },
    // }).then(response => {
    //   if (response.status === 200) {
    //     toast.success("Training deleted successfully.", {
    //       position: toast.POSITION.BOTTOM_RIGHT
    //     });
    //     console.log("Training deleted successfully.");
    //     window.location.reload(true);
    //   }
    // });
    setRowData(rowData);
    setShowDialogDelete(true);
  };

  const handleEdit = (rowData) => {
    navigate("/edit", { state: { rowData } });
  };

  const handlePoster = (rowData) => {
    console.log("Poster clicked for row data:", rowData);
    setRowData(rowData);
    setShowDialog(true);
  };
  const handleChunk = (rowData) => {
    console.log("Poster clicked for row data:", rowData);
    setRowData(rowData);
    setShowDialogChunk(true);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_LINK}/upload/poster/${rowData.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Poster added successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              console.log("Company added successfully.");
              window.location.reload(true);
            }
          });

        setSelectedFile(null);
        setShowDialog(false);
      } catch (error) {
        console.error("Upload error:", error);
      }
    }
  };
  const handleChunkUpload = async () => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_LINK}/vr/update/training/${selectedPlatform}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Poster added successfully.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              console.log("Company added successfully.");
              window.location.reload(true);
            }
          });

        setFile(null);
        setShowDialogChunk(false);
      } catch (error) {
        console.error("Upload error:", error);
      }
    }
  };


  const items = Object.entries(data);

  const itemTemplate = (item) => {
    const [key, value] = item;

    const handleButtonClick = (event) => {
      setRowData(value);
      overlayPanelRef.current.toggle(event);
    };

    return (
      <li className="item" key={key}>
        <div className="image-container">
          <img
            src={`${process.env.REACT_APP_BACKEND_LINK}/poster/${value.poster}`}
            alt="poster"
          />
        </div>
        <div className="name-container">
          <h5>{value.name}</h5>
        </div>
        <div className="button-con">
          <Button
            icon="pi pi-ellipsis-h"
            className="dot-button"
            style={{
              background: "none",
              border: "none",
              boxShadow: "none",
              color: "rgba(46, 58, 75, 0.5)",
            }}
            onClick={(event) => handleButtonClick(event, value)}
          />
        </div>
      </li>
    );
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="loading-screen">
          <ProgressSpinner />
        </div>
      ) : (
        <ul className="list">{items.map(itemTemplate)}</ul>
      )}
      <OverlayPanel ref={overlayPanelRef}>
        {rowData && overlayPanelContent}
      </OverlayPanel>

      <Dialog
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        header={<div style={{ marginTop: "-25px" }}>Add Poster</div>}
        modal
        closable={false}
        style={{ width: "400px" }}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              label="Upload"
              className="upload-button"
              onClick={handleFileUpload}
              disabled={!selectedFile}
            />
            <Button
              label="Cancel"
              className="cancel-button"
              onClick={() => {
                setSelectedFile(null);
                setShowDialog(false);
              }}
            />
          </div>
        }
      >
        <div className="file-upload-section">
          <h4>{rowData && rowData.name}</h4>
          <div className="file-upload-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <label htmlFor="file-upload" className="file-upload-label">
              Select File
            </label>
            <input
              type="file"
              id="file-upload"
              accept=".jpg"
              onChange={handleFileSelect}
              className="file-upload-input"
            />
            <div className="selected-file">
              {selectedFile && (
                <>
                  <span className="selected-file-name">
                    {selectedFile.name}
                  </span>
                  <span
                    className="file-remove"
                    onClick={() => setSelectedFile(null)}
                  >
                    <i className="pi pi-times" />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={showDialogChunk}
        onHide={() => setShowDialogChunk(false)}
        header={<div style={{ marginTop: "-25px" }}>Update chunk</div>}
        modal
        closable={false}
        style={{ width: "600px" }}
        footer={
         <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
  <Button
    label="Upload"
    className="upload-button"
    onClick={handleChunkUpload}
    disabled={!file}
  />
  <Button
    label="Cancel"
    className="cancel-button"
    onClick={() => {
      setSelectedFile(null);
      setShowDialogChunk(false);
      setSelectedPlatform(null);
    }}
  />
</div>

        }
      >
        <div className="file-upload-section">
          <h4>Name: {rowData && rowData.name}</h4>
          <h4>ID: {rowData && rowData.id}</h4>
          <Dropdown
          value={selectedPlatform}
          options={["pico","quest","windows"]} // Add the platform options here
          onChange={async (e)  => {setSelectedPlatform(e.value)          
          }}
          placeholder="Select Platform"
          style={{ width: '100%', height: '36px', display: 'flex', alignItems: 'center' }}
        />
      
          <div className="flex flex-row gap-2" style={{marginTop: "20px"}}>
                <DropzoneUploader
                  onChangeStatus={onUpload}
                  accept=".pak"
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  inputContent={
                    <div>
                      <div
                        className="flex flex-raw gap-2"
                        style={{ marginLeft: "12px" }}
                      >
                        <i
                          className="pi pi-upload"
                          style={{
                            fontSize: "1.2rem",
                            color: "#005DFF",
                            fontWeight: "bold",
                          }}
                        ></i>
                        <div className="gidota">Upload build</div>
                      </div>
                      <div className="gadost">
                        or drag & drop your file here
                      </div>
                    </div>
                  }
                  PreviewComponent={Preview}
                  styles={{
                    dropzone: {
                      minHeight: 50,
                      border: isValidFile
                        ? "2px dashed rgb(0,0,0,0.5)"
                        : "2px dashed red",
                      borderRadius: 5,
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      width: "100%",
                      height: "80px",
                      transition: "none",
                    },
                    inputLabel: {
                      color: "#333",
                      fontSize: 14,
                    },
                    input: {
                      display: "none",
                    },
                    preview: {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      position: "static",
                      transition: "none",
                    },
                    previewImage: {
                      maxHeight: 200,
                      maxWidth: "100%",
                    },
                    slider: {
                      display: "none",
                    },
                    dropzoneActive: {
                      borderStyle: "solid",
                      backgroundColor: "#eee",
                    },
                    previewContainer: {
                      transition: "none !important",
                    },
                  }}
                />
                {isLoading ? (
                  <div className="loading-screen">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    {file ? (
                      <div className="flex flex-col items-center"></div>
                    ) : null}
                  </div>
                )}
              </div>
        </div>
      </Dialog>

      {/* Add Assign Dialog */}
      <Dialog
        visible={showDialogAssign}
        onHide={handleCloseDialogAssign}
        header={<div style={{ marginTop: "-25px" }}>Assign Training</div>}
        modal
        style={{ width: "450px", height: "310px" }}
        closable={false}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              label="Assign"
              className="upload-button"
              style={{ marginTop: "-55px" }}
              onClick={async () => {
                try {
                  for (const selectedCompany of selectedCompanies) {//debug
                    console.log("Selected company:", selectedCompany);
                    const companyId = response.find(
                      (option) => option.mail === selectedCompany.mail
                    ).id;

                    const requestBody = {
                      company: companyId,
                      training: rowData.id,
                    };

                    await axios
                      .post(
                        `${process.env.REACT_APP_BACKEND_LINK}/vr/exclusive/add/training`,
                        requestBody,
                        {
                          headers: {
                            Authorization: "Bearer " + token,
                          },
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          console.log("Training assigned successfully.");
                        }
                        toast.success("Training assigned successfully.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      });
                  }

                  setShowDialogAssign(false);
                } catch (error) {
                  console.error("Assign error:", error);
                }
                window.location.reload(true);
              }}
              disabled={!selectedCompanies.length} // Disable the Assign button if no company or platform is selected
            />
            <Button
              label="Cancel"
              className="cancel-button"
              style={{ marginTop: "-55px" }}
              onClick={() => {
                setSelectedCompanies([]);
                setSelectedPlatform(null); // Reset the selected platform
                setShowDialogAssign(false);
              }}
            />
          </div>
        }
      >
        <div style={{ width: "400px" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {rowData && rowData.name}
          </p>
          <div style={{ marginTop: "25px" }}>
            <div style={{ width: "392px", marginTop: "5px" }}></div>
          </div>
          <div style={{ marginTop: "25px" }}>
            <label
              htmlFor="company-dropdown"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              Company
            </label>
            <div style={{ width: "392px", marginTop: "5px" }}>
              <MultiSelect
                value={selectedCompanies}
                onChange={(e) => setSelectedCompanies(e.value)}
                options={response}
                optionLabel="mail"
                filter
                style={{
                  width: "100%",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                }}
                placeholder="Select Companies"
                optionDisabled={(option) => option.used === 1}
              />
            </div>
          </div>

          <div style={{ marginTop: 40 }}></div>
        </div>
      </Dialog>

      <Dialog
        visible={showDialogUnassign}
        onHide={handleCloseDialogUnassign}
        header={<div style={{ marginTop: "-25px" }}>Unassign Training</div>} // Add the marginTop to adjust the header position
        modal
        style={{ width: "450px", height: "310px" }}
        closable={false}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              label="Unassign"
              className="upload-button"
              style={{ marginTop: "-55px", width: "100px"}}
              onClick={async () => {
                try {
                  for (const selectedCompany of selectedCompanies) {
                    console.log("Selected company:", selectedCompany);
                    const companyId = response.find(
                      (option) => option.mail === selectedCompany.mail
                    ).id;

                    const requestBody = {
                      company: companyId,
                      training: rowData.id,
                    };

                    await axios
                      .post(
                        `${process.env.REACT_APP_BACKEND_LINK}/vr/exclusive/remove/training`,
                        requestBody,
                        {
                          headers: {
                            Authorization: "Bearer " + token,
                          },
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          console.log("Training assigned successfully.");
                        }
                        toast.success("Training assigned successfully.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      });
                  }

                  window.location.reload(true);
                } catch (error) {
                  console.error("Assign error:", error);
                }

                setShowDialogUnassign(false);
              }}
              disabled={!selectedCompanies.length}
            />
            <Button
              label="Cancel"
              className="cancel-button"
              style={{ marginTop: "-55px" }}
              onClick={() => {
                setSelectedCompanies([]);
                setSelectedPlatform(null); // Reset the selected platform
                setShowDialogUnassign(false);
              }}
            />
          </div>
        }
      >
        <div style={{ width: "400px" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {rowData && rowData.name}
          </p>
          <div style={{ marginTop: "25px" }}>
            <div style={{ width: "392px", marginTop: "5px" }}></div>
          </div>
          <div style={{ marginTop: "25px" }}>
            <label
              htmlFor="company-dropdown"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              Select Companies
            </label>
            <div style={{ width: "392px", marginTop: "5px" }}>
              <MultiSelect
                value={selectedCompanies}
                onChange={(e) => setSelectedCompanies(e.value)}
                options={response}
                optionLabel="mail"
                filter
                style={{
                  width: "100%",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                }}
                placeholder="Select Companies"
                optionDisabled={(option) => option.used === 0}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={showDialogDelete}
        onHide={handleCloseDialogDelete}
        header={<div style={{ marginTop: "-25px", fontWeight: "600" }}>Delete Training</div>} // Add the marginTop to adjust the header position
        modal
        style={{ width: "450px", height: "260px" }}
        closable={false}
        footer={
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              label="Delete"
              className="delete-button"              
              onClick={async () => {
                try {
                  console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
                  console.log(rowData.id);
                  console.log(selectedPlatform);

                  const requestBody = {
                    id: rowData.id,
                  };

                  await axios
                    .post(
                      `${process.env.REACT_APP_BACKEND_LINK}/vr/delete/training`,
                      requestBody,
                      {
                        headers: {
                          Authorization: "Bearer " + token,
                        },
                      }
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        console.log("Training deleted successfully.");
                        toast.success("Training deleted successfully.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      } else {
                        toast.error("Training not deleted.", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      }
                    });

                  window.location.reload(true);
                } catch (error) {
                  console.error("Assign error:", error);
                }

                setShowDialogDelete(false);
              }}
              //disabled={!selectedPlatform}
            />
            <Button
              label="Cancel"
              className="cancel-button"              
              onClick={() => {
                setSelectedCompanies([]);
                setSelectedPlatform(null); // Reset the selected platform
                setShowDialogDelete(false);
              }}
            />
          </div>
        }
      >
        <div style={{ width: "400px" }}>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {rowData && rowData.name}
          </p>
          <div style={{ marginTop: "25px", marginLeft: "35px" }}>
            <label
              htmlFor="platform-dropdown"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              Are tou sure you want to delete this training?
            </label>
            {/* <div style={{ width: '392px', marginTop: '5px' }}>
        <Dropdown
          value={selectedPlatform}
          options={["pico","quest","windows"]} // Add the platform options here
          onChange={async (e)  => {setSelectedPlatform(e.value)          
          }}
          placeholder="Select Platform"
          style={{ width: '100%', height: '36px', display: 'flex', alignItems: 'center' }}
        />
      </div> */}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default VRTraining;
